"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    :host,
    :host(:hover),
    :host(:active),
    :host([active]) {
        --primary-button-color: var(--app-primary-color);
        --hv-button-text-transform: uppercase;
        --font-color: var(--white);
        --hv-button-height: 50px;
        --border-radius: 5px;
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
};
