'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    appTitle: 'Groene Stroom Lokaal',
    logoRedirect: 'https://groenestroomlokaal.nl',
    electricityAccentColor: '#008244',
    electricityProductionAccentColor: '#008244',
    gasAccentColor: '#008244',
    savingsAccentColor: '#008244',
    energyCostAccentColor: '#008244',
    subtitle: '',
    logoAppLink: '', // Je kunt hier een redirect invullen. Die is dan voor geauthenticeerde, als niet geauthenticeerde users.
    PhoneNumber: '085 808 5151',
    appDomain: 'Groene Stroom Lokaal',
    vendorID: 'e99fa642-e2eb-11ea-87d0-0242ac130003',
    smartCharging: false
};