'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
// @ts-nocheck
exports.default = {
  /**
   * The suffix to show in the title
   */
  titleSuffix: ' prijsopbouw'
};