"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    // styles
    styleObject: {
        paddingBottom: '10px',
    },
    label: {
        submit: 'nu inloggen',
    },
};
