'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    welcomeVariant: 2,
    faqURL: 'https://groenestroomlokaal.zendesk.com/hc/nl',
    priceCapURL: 'https://groenestroomlokaal.zendesk.com/hc/nl/articles/7732044931997-Prijsplafond-2023'
};