"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    // styles
    barColor: '#006a69',
    barHoverColor: '#006a69',
    lineColor: '#95a4ac',
    axisLabelColor: 'black',
    legendaInformation: ``,
};
