'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    title: 'Inloggen',
    body: 'Log in om toegang te krijgen tot de klantomgeving.',
    emailPlaceholder: 'naam@domein.nl',
    passwordPlaceholder: 'Wachtwoord',
    submit: 'Nu inloggen',
    newCustomer: 'Maandbedrag berekenen',
    forgotPassword: 'Wachtwoord vergeten?'
};