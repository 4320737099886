'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    title: 'Ben jij al klant?',
    bodyText: `Zo ja, dan heb jij als klant al een energiecontract bij Groene Stroom Lokaal lopen en willen wij deze nieuwe aanvraag 
    of verlenging graag toevoegen aan jouw huidige account. Log in en jouw gegevens worden automatisch ingeladen. Zo nee, start dan een 
    nieuwe aanvraag.`,
    buttonExistingCustomerText: 'Nu inloggen',
    buttonNewCustomerText: 'Nieuwe aanvraag',
    backButtonText: 'Vorige stap'
};