'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    title: 'Wachtwoord vergeten?',
    body: 'Vul hieronder je e-mailadres in en stel direct per e-mail je nieuwe wachtwoord in.',
    inputPlaceholder: 'naam@domein.nl',
    submit: 'Versturen'
};